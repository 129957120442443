$pr1: #C7E1ED
$pr2: #f9ddf9
$pr3: #F1E5AA
$pr4: #E3EFB7
$pr5: #B2F0E5
$body: #fff
$theme: #f47b1e
$dark: #363636
$white: #fff
$light: #F5F5F5
$medium: #505050

$button: $theme
$intro: $dark

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd

$basic: sans-serif
$inter: $basic

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
