
html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $medium
  font-size: 95%
  font-family: $basic
  font-weight: 500
  font-smooth: always
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $white

a, * a
  color: inherit !important
  text-decoration: none !important

#reviews
  .card
    margin: 0 24px
    padding: 40px 0
    background-color: #fff
    display: block
    position: relative
    &::after
      position: absolute
      left: 24px
      bottom: 0
      color: #7a7a7a
      content: '[+]'
      transform: translate(18px,-24px)
    &:hover
      &::after
        display: none
  img
    border-radius: 16px
    border: 2px solid $theme
  h4
    font-weight: 500
.exc
  width: clamp(20ch, 80%, 70ch)
  height: clamp(11ch, 6.2vh, 14ch)
  line-clamp: 3
  transform: translateX(24px)
  overflow: hidden
  &:hover
    height: auto
    overflow: visible

footer
  display: flex
  justify-content: center
  align-items: flex-end
  width: 100%
  img
    filter: grayscale(100%)
  +v-sm
    display: block
    padding: 20px
    height: auto
    min-height: auto !important
    text-align: center !important
  p,
  .tr
    +v-sm
      padding: 10px 0
      text-align: center !important

img
  +v-sm
    width: auto !important
    margin-left: auto !important
    margin-right: auto !important

.s-100
  +v-sm
    display: none !important
    height: 0 !important
.s-100
  +v-xs
    display: none !important
    height: 0 !important

.s-m-40
  display: none
  +v-sm
    display: block
    height: 40px

.container
  +v-sm
    padding: 0 10%

#top
  margin-top: -100px
  padding-top: 80px
  width: 100%
  height: auto
  min-height: 100vh
  background-color: #fdfdfd
  +v-sm
    padding: 200px 0 40px
    min-height: auto
  .button
    &.mx-4
      +v-sm
        margin-left: 0 !important
        margin-right: 0 !important

#about
  width: 100%
  height: auto
  //background: $white url('../../assets/img/bgs/cloud.webp') right center no-repeat
  +v-sm
    min-height: auto

.bg-mission
  background: $darkblue url('../../assets/img/bgs/uny.webp') center center no-repeat
  background-blend-mode: multiply

.bg-help
  background: $dark url('../../assets/img/bgs/shake.webp') center center no-repeat
  background-blend-mode: multiply

.numbers-animated
  color: $theme
  font-size: 60px
  font-weight: 800 !important

.card-partners
  position: relative
  min-height: 260px
  span
    position: absolute
    bottom: 50px
    font-weight: 600
  &.p1
    background: $darkblue url('../../assets/img/pics/pic005.webp') center center no-repeat
    background-blend-mode: soft-light
    background-size: cover
  &.p2
    background: $grey url('../../assets/img/pics/pic002.webp')
    background-blend-mode: soft-light
    background-size: cover
  &.p3
    background: $darkblue url('../../assets/img/pics/pic003.webp')
    background-blend-mode: soft-light
    background-size: cover
  &.p4
    background: $grey url('../../assets/img/pics/pic004.webp')
    background-blend-mode: soft-light
    background-size: cover
  &.p5
    background: $darkblue url('../../assets/img/pics/pic006.webp')
    background-blend-mode: soft-light
    background-size: cover
  &.p6
    background: $grey url('../../assets/img/pics/pic001.webp')
    background-blend-mode: soft-light
    background-size: cover

.bg-cta
  background: $darkblue url('../../assets/img/bgs/power.webp')
  background-blend-mode: soft-light
  background-size: cover

.card-list
  margin: 0
  padding: 0
  list-style: none
  line-height: 2
  font-weight: 500

#projects
  .button
    &.outline
      +v-sm
        width: 30% !important
        min-width: 30% !important
        margin-right: auto !important
        margin-left: 0 !important
      +v-xs
        width: 70% !important
        min-width: 70% !important
        margin-right: 10% !important
  .card-projects
    img
      +v-sm
        display: block
        margin-left: auto !important
        margin-right: 0 !important
    +v-sm
      padding: 8px 24px !important
      margin: 10px 0 !important
    +v-xs
      padding: 8px 24px !important
      margin: 10px auto !important
    .w-100
      &.h-80
        +v-sm
          height: 300px !important
          min-height: 300px !important
        +v-xs
          height: 200px !important
          min-height: 200px !important
    .s-33,
    .s-20
      +v-sm
        height: 0 !important
        max-height: 0 !important
        padding: 0 !important
    .py-4
      +v-sm
        padding: 0 !important
.mx-5
  &.mob-table
    +v-sm
      margin-left: -20px !important
      margin-right: 0 !important
    +v-xs
      margin-left: -20px !important
      margin-right: 0 !important

#we
  position: relative
  .w-50
    +v-sm
      width: 100% !important
      min-width: 100% !important
  .pic
    position: absolute
    right: 0
    top: 80px
    width: 40%
    z-index: 999
    +v-sm
      display: none

.bg
  &-pr1
    background-color: $pr1
  &-pr2
    background-color: $pr2
  &-pr3
    background-color: $pr3
  &-pr4
    background-color: $pr4
  &-pr5
    background-color: $pr5

.table-mob
  td,
  th
    padding: 0 40px 10px
    +v-sm
      padding: 0 5%
    +v-xs
      zoom: .75
      padding: 0 5%
      transform: translateY(-40px)

header
  a
    color: $medium !important
    transition: all ease-out .3s
    &:hover
      color: $theme !important
  +v-sm
    position: fixed
    top: -20px
    left: 0
    width: 100%
    height: 80px
    max-height: 80px !important
    padding: 0 24px !important
    margin: 0 !important
    border-bottom: 1px solid $light
    background-color: $white
  #logo
    +v-sm
      transform: translate(-6px,14px)
  .menu
    display: flex
    justify-content: flex-end
    align-items: center
    +v-sm
      position: fixed
      display: block
      top: 76px
      left: 0
      margin: 0 !important
      padding: 0 !important
      width: 100%
      height: 100vh
      min-height: 100vh
      overflow: hidden
      background-color: #fff
      z-index: 999 !important
      overflow-x: hidden
      overflow-x: hidden
      transition: left ease-out .5s
      &.activeMenu
        left: 120%
      a
        display: flex
        align-items: center
        width: 100%
        height: 60px
        padding: 0 24px !important
        border-top: 1px solid $light
        transform: translateX(-10px)
        &.social
          background-color: $light
          transform: translateX(-16px)

section
  +v-sm
    position: relative
    z-index: -1

.burger
  display: none
  +v-sm
    position: fixed
    top: 30px
    right: 50px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $medium
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $medium
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)

.mobile
  +v-sm
    transform: translate(-4px,11px)
  .in
    +v-sm
      transform: translateY(1px)

.flex
  &.m-block
    +v-sm
      display: block
      margin: 0 !important
  &.sign
    img
      +v-sm
        display: inline-block
        margin: 4px !important

.m-none
  +v-sm
    display: none
.m-icon
  +v-sm
    height: 100px !important
    margin-bottom: 16px
    padding: 0
  +v-xs
    width: 48px !important
    height: 72px !important
    margin-bottom: 16px
    padding: 0
.m-30
  +v-sm
    margin-top: 30px
.m-pad
  +v-sm
    padding: 12px 24px !important
    margin-bottom: 30px
.m-bottom
  +v-sm
    padding-bottom: 20px !important
.m-40
  +v-sm
    padding-top: 40px !important

#contacts
  & .wrapper
    +v-md
      flex-direction: column
      justify-content: flex-start

#feed-back
  & .wrapper
    +v-sm
      flex-direction: column
      align-items: center
  & .item
    +v-sm
      max-width: 100% !important
      margin-bottom: 20px

.active
  color: $theme !important
