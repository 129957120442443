.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 44px
  padding: 0 30px
  color: $dark !important
  font-size: 1em
  font-weight: 500
  border-radius: 4px
  transition: all ease-out .25s
  background-color: $white !important
  border: 2px solid $button !important
  outline: none !important
  +v-sm
    display: flex
    width: 60%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    margin: 24px 0
    padding: 0
    font-size: 0.82em
#top
  .button
    +v-sm
      display: inline-flex
      width: 40%
      margin: 0 24px 0 0
    +v-xs
      display: flex
      width: 100%
      margin: 24px 0

a
  &.button
    &:hover
      color: $white !important
      background-color: $marine !important
      border-color: $marine !important
